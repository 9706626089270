<template>
  <div>
    <font-awesome-icon icon="eye" @click="handleClickEye" />
    <b-modal
      ref="ModalIframePdfShow"
      id="ModalIframePdfShow"
      no-close-on-backdrop
      :hide-footer="true"
      title=""
      size="lg"
      modal-class="cutsom-modal-bootstrap mdalTaille"
    >
      <div class="message text-aligne-center" v-if="error">
        <div class="error-message">{{ error }}</div>
      </div>
      <div v-if="loading" class="loading-custom-template-block">
        <Half-circle-spinner
          :animation-duration="1000"
          :size="50"
          :color="'#4d4bac'"
          class="loeder"
        />
      </div>

      <iframe
        v-else
        class="iframe-position"
        width="1000"
        height="900"
        :src="computedSrc"
      ></iframe>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: { getDataPDF: { required: true }, data: { required: true } },
  data() {
    return {
      src: null,
      loading: false,
      error: null
    };
  },
  methods: {
    async handleClickEye() {
      this.error = null;
      this.$refs['ModalIframePdfShow'].show();
      this.loading = true;
      const response = await this.getDataPDF(this.data);
      if (response.succes) {
        this.src = response.src;
      } else {
        this.error = response.error;
      }
      this.loading = false;
    }
  },
  computed: {
    computedSrc() {
      return this.src;
    }
  }
};
</script>

<style lang="scss" scoped>
.iframe-position {
  display: block;
  margin: auto;
  width: 750px;
  height: 92%;
  margin-top: 5px;
}
</style>
<style lang="scss">
.mdalTaille {
  .modal-dialog {
    height: 98%;
  }
  .modal-content {
    height: 100%;
  }
}
</style>
